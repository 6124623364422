#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  background: #525252; /* fallback for old browsers */
  background: -webkit-linear-gradient(135deg, #3d72b4, #525252); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    135deg,
    #897ae3,
    #27abec
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

html,
body {
  position: relative;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper {
  width: 300px;
  height: 400px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 16px;

  /* font-weight: bold; */
  /* color: #fff; */
}

.swiper-slide:nth-child(1n) {
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgrey;
  box-shadow: 3px 3px 3px grey;
}

.swiper-slide:nth-child(2n) {
  /* background-color: rgb(0, 140, 255); */
}

.swiper-slide:nth-child(3n) {
  /* background-color: rgb(10, 184, 111); */
}

.swiper-slide:nth-child(4n) {
  /* background-color: rgb(211, 122, 7); */
}

.swiper-slide:nth-child(5n) {
  /* background-color: rgb(118, 163, 12); */
}

.swiper-slide:nth-child(6n) {
  /* background-color: rgb(180, 10, 47); */
}

.swiper-slide:nth-child(7n) {
  /* background-color: rgb(35, 99, 19); */
}

.swiper-slide:nth-child(8n) {
  /* background-color: rgb(0, 68, 255); */
}

.swiper-slide:nth-child(9n) {
  /* background-color: rgb(218, 12, 218); */
}

.swiper-slide:nth-child(10n) {
  /* background-color: rgb(54, 94, 77); */
}

/* ======================================== FLOATING BLOCKS */

@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  /* color: #fff; */
  font-size: 50px;
}

/* .area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
} */

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 10%;
  }
}
